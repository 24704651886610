<template>
  <div>
    <van-nav-bar
      class="backBox"
      title="开票记录"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />

    <div class="page">
      <div class="mainWrap">
        <div class="query_box">
          <!-- <div class="query_box_item">
            <span class="query_box_item_tit">车牌号码</span>
            <div class="query_box_item_right">
              <div class="section">
                <span class="selPlateNum" @click="openVhPopup">{{
                  selVhVal ? selVhVal : '选择车牌号码'
                }}</span>
                <img
                  src="../../../assets/img/rightjt@2x.png"
                  class="rightIcon"
                />
                <van-popup
                  v-model="showVhPopup"
                  @close="onVhClose"
                  position="bottom"
                >
                  <van-picker
                    :columns="vhColumns"
                    @change="onVhChange"
                    @confirm="onVhConfirm"
                    @cancel="onVhCancel"
                    show-toolbar
                    title="选择车牌号码"
                    visible-item-count="5"
                    default-index="0"
                  />
                </van-popup>
              </div>
            </div>
          </div> -->

          <div class="query_box_item">
            <span class="query_box_item_tit">时间范围</span>
            <div class="query_box_item_right" @click="openDate">
              <div class="section">
                <span class="showCommon">
                  {{ currentDateStartFormat }} 至 {{ currentDateEndFormat }}</span
                >

                <img
                  src="../../../assets/img/rightjt@2x.png"
                  class="rightIcon"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="list_box">
         <van-tabs v-model="active" @change="onChange" sticky color="#4797EA" title-active-color="#4797EA">
           <van-tab title="停车缴费">
          <van-pull-refresh
            v-model="refreshing"
            @refresh="onRefresh"
            success-text="刷新成功"
            :disabled="errorFlag"
          >
            <van-list
              v-model="loading"
              :finished="finished"
              :immediate-check="false"
              :finished-text="parkPayRecordList.length===0?'':'没有更多了'"
              :error.sync="error"
              error-text="请求失败，点击重新加载"
              @load="onLoad"
            >
              <div
                class="group_recharge"
                v-for="item in parkPayRecordList"
                :key="item.id"
              >
                <div class="itemBox">
                  <div class="text-wrapper_6">
                    <div class="plateNum">
                      <img
                        src="../../../assets/img/fapiaojilu1@2x.png"
                        class="icon_3"
                        mode=""
                      />
                      <span class="text_12">{{ item.plate_number }}</span>
                    </div>
                    <!-- <span class="text_13">3小时</span> -->
                  </div>
                  <div class="lineline"></div>
                  <!-- <van-divider/> -->

                  <!-- <div class="text-wrapper_6">
                      <span class="text_12">停车场</span>
                      <span class="text_13">银湖停车场</span>
                    </div> -->
                  <div class="text-wrapper_6">
                    <span class="text_12">停车时长</span>
                    <span class="text_13">{{ getParkTimeFormatter(item) }}</span>
                  </div>
                  <div class="text-wrapper_6">
                    <span class="text_12">入场时间</span>
                    <span class="text_13">{{ formatDate(item.in_time) }}</span>
                  </div>
                  <div class="text-wrapper_6">
                    <span class="text_12">出场时间</span>
                    <span class="text_13">{{ formatDate(item.out_time) }}</span>
                  </div>
                  <div class="text-wrapper_6">
                    <span class="text_12">停车费用</span>
                    <span class="text_13">{{
                      item.actually_paid ? Number(item.actually_paid) / 100 : ''
                    }}</span>
                  </div>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>
          <!-- 空状态 -->
          <van-empty
            v-if="parkPayRecordList.length == 0"
            description="暂无匹配数据"
          />
          <van-empty
            v-if="parkPayRecordList.length !== 0 && isError"
            image="error"
            description="查询异常"
          />
           </van-tab>
           <van-tab title="月卡充值">
            <van-pull-refresh
              v-model="refreshingMonth"
              @refresh="onRefreshMonth"
              success-text="刷新成功"
              :disabled="errorFlagMonth"
            >
              <van-list
                v-model="loadingMonth"
                :finished="finishedMonth"
                :immediate-check="false"
                :finished-text="monthPayRecordList.length===0?'':'没有更多了'"
                :error.sync="errorMonth"
                error-text="请求失败，点击重新加载"
                @load="onLoadMonth"
              >
                <div
                  class="group_recharge"
                  v-for="item in monthPayRecordList"
                  :key="item.id"
                >
                  <div class="itemBox">
                     <div class="text-wrapper_6">
                      <div class="plateNum">
                        <img
                          src="../../../assets/img/fapiaojilu2@2x.png"
                          class="icon_3"
                          mode=""
                        />
                        <span class="text_12">{{ item.plate_number }}</span>
                      </div>
                      <!-- <span class="text_13">{{getParkTimeFormatter(item)}}</span> -->
                    </div>
                    <div class="lineline"></div>

                    <div class="text-wrapper_6">
                      <span class="text_12">缴费类型</span>
                      <span class="text_13">月卡充值</span>
                    </div>
                    <div class="text-wrapper_6">
                      <span class="text_12">缴费时间</span>
                      <span class="text_13">{{formatDate(item.create_time)}}</span>
                    </div>
                    <div class="text-wrapper_6">
                      <span class="text_12">缴费金额</span>
                      <span class="text_13">{{item.actually_paid / 100}}</span>
                    </div>
                    <div
                      class="lineline"
                      v-if="monthPayRecordList.length - 1 !== index"
                    ></div>
                  </div>
                </div>
              </van-list>
            </van-pull-refresh>
            <van-empty
              v-if="monthPayRecordList.length == 0"
              description="暂无匹配数据"
            />
            <van-empty
              v-if="monthPayRecordList.length !== 0 && isError"
              image="error"
              description="查询异常"
            />
          </van-tab>
          </van-tabs>
        </div>
      </div>
      <!-- 时间选择器 -->
      <date-select
        :show-date="showDatePopup"
        @get-on-close="getOnCloseDate"
        @doneHaveSelDate="doneHaveSelDate"
      ></date-select>
      <!-- 分页 -->
      <!-- <Pagination
      :total="pageOption.total"
      :page_no="pageOption.page_no"
      :page_size="pageOption.page_size"
      @page-change="handlePageChange"
    ></Pagination> -->
    </div>
  </div>
</template>
<script>
import DateSelect from '../../../components/DateSelect.vue'
// import Pagination from '../../../components/Pagination.vue'
import * as common from '../../../api/common'
import * as api from '../../../api/api'
import * as util from '../../../api/util'
// import { Toast } from 'vant'
// import moment from 'moment'
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'
export default {
  // components: { DateSelect, Pagination },
  components: { DateSelect },
  data () {
    return {
      tokenInfoObj: {},
      active: 0,

      show: false,

      objectArray: [{
        id: 0,
        money: '22',
        groupName: '银湖分组',
        date: '2022/12/12 12:12:12',
        src: '../../../assets/img/rightjt.png',
        plateNum: '浙A88899'
      },
      {
        id: 1,
        money: '25',
        groupName: '银湖分组2',
        date: '2023-11-11 13:13:13',
        src: '../../../assets/img/rightjt.png',
        plateNum: '浙A88899'
      },
      {
        id: 2,
        money: '25',
        groupName: '银湖分组2',
        date: '2023-11-11 13:13',
        src: '../../../assets/img/rightjt.png',
        plateNum: '浙A88899'
      }
      ],
      objectArray2: [{
        id: 0,
        money: '22',
        groupName: '银湖缴费分组',
        date: '2022-12-12 12:12',
        src: '../../../assets/img/rightjt.png'
      },
      {
        id: 1,
        money: '25',
        groupName: '银湖缴费分组',
        date: '2023-11-11 13:13',
        src: '../../../assets/img/rightjt.png'
      },
      {
        id: 2,
        money: '25',
        groupName: '银湖缴费分组',
        date: '2023-11-11 13:13',
        src: '../../../assets/img/rightjt.png'
      }
      ],
      // vhColumns: [],
      index: 0,
      array2: ['今天', '明天', '前天', '巴西', '日本'],
      index2: 0,
      recentDates: '',
      lastAmonth: '',
      lastThreeMonth: '',
      plateNum: '',
      user_info: {},
      token: '',
      parkPayRecordList: [

      ],
      monthPayRecordList: [
      ],
      isError: false,
      showVhPopup: false,
      selVhVal: '',
      showDatePopup: false,
      currentDateStartFormat: '',
      currentDateEndFormat: common.fmtDate(new Date(), 'yyyy-MM-dd'),
      pageOption: {
        page_no: 1,
        page_size: 10,
        total: 0
      },
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      errorFlag: false,
      loadingMonth: false,
      finishedMonth: false,
      refreshingMonth: false,
      errorMonth: false,
      errorFlagMonth: false,
      qryFlag: false

    }
  },
  mounted () {
    // this.queryTab()
    // 从今天算 往前推半年时间的日期
    const nowDate = new Date()
    const startTim = new Date(nowDate.getFullYear(), nowDate.getMonth() - 6, nowDate.getDate())
    const startDateTime = common.format(startTim, 'yyyy-MM-dd')
    this.currentDateStartFormat = startDateTime
    this.currentDateStartFormat = util.getDateOneMonthBeforeToday(this.currentDateEndFormat)

    this.queryParkPayRecord()
  },
  computed: {
    ...mapState({
      sid: state => state.sid.sid,
      openid: state => state.openID.openID

    }),
    ...mapGetters(['vhColumns', 'openID']

    )
  },
  methods: {
    getParkTimeFormatter (item) {
      return util.countTimeStartEnd(item.in_time, item.create_time)
    },
    startTimeFormatter (item) {
      if (!item || !item.local_info || !item.local_info.start_time) return ''
      return common.format(new Date(item.local_info.start_time), 'yyyy-MM-dd hh:mm:ss')
    },
    endTimeFormatter (item) {
      if (!item || !item.local_info || !item.local_info.end_time) return ''
      return common.format(new Date(item.local_info.end_time), 'yyyy-MM-dd hh:mm:ss')
    },
    parkTimeFormatter (item) {
      if (!item || !item.local_info || !item.local_info.parked_time) return ''
      return util.parkTimeformat(item.local_info.parked_time)
    },
    formattedDate (dateString) {
      return dateString.replace(/-/g, '/') //
    },
    formatDate (date) {
      return common.fmtDate(date, 'yyyy/MM/dd hh:mm:ss')
    },
    handlePageChange (page) {
      this.queryTab()
    },
    doneHaveSelDate (date1, date2) {
      this.currentDateStartFormat = date1
      this.currentDateEndFormat = date2
      this.showDatePopup = false
      this.qryFlag = true
      this.queryParkPayRecord()
    },
    getOnCloseDate () {
      this.showDatePopup = false
    },
    openVhPopup () {
      this.showVhPopup = true
    },
    onVhClose () {

    },
    onVhChange () {

    },
    onVhConfirm (value) {
      this.selVhVal = value
      this.showVhPopup = false
      this.qryFlag = true
      this.queryParkPayRecord()
    },
    onVhCancel () {
      this.showVhPopup = false
    },
    openDate () {
      this.showDatePopup = true
    },
    queryTab () {
      this.qryFlag = true

      if (this.active === 0) {
        this.queryParkPayRecord()
      } else {
        this.queryMonthPayRecord()
      }
    },
    // 切换Tab
    onChange () {
      this.pageOption.page_no = 1
      this.queryTab()
    },
    // 查询停车缴费
    async queryParkPayRecord () {
      try {
        const { data } = await axios({
          url: common.iotUrl + '/uuid/' + api.urlList.car_owner.bill.get_bill,
          method: 'POST',
          data: {
            // sid: 'yuen03',
            sid: this.sid,
            // openid: 'o1pxwuMcjjXt2YtCXSl3UC-ZbK-w',
            openid: this.openID,
            page_no: this.pageOption.page_no,
            page_size: this.pageOption.page_size,
            begin_time: this.currentDateStartFormat + ' 00:00:00',
            end_time: this.currentDateEndFormat + ' 23:59:59',
            version: 'v2',
            type: this.active === 0 ? 1 : 2
          },
          header: {
            'content-type': 'application/json' // 默认值
          }
        })
        if (data.code === common.resCode.OK) {
          const haveSuccessList = data.list.filter(item => item.bill_status === 2)
          this.pageOption.total = haveSuccessList.length

          // const haveSuccessList = data.list
          if (this.refreshing || this.qryFlag) {
            this.parkPayRecordList = haveSuccessList
            this.qryFlag = false
            this.loading = false
            this.refreshing = false
          } else {
            this.parkPayRecordList = [...this.parkPayRecordList, ...haveSuccessList]
            this.loading = false
            if (this.parkPayRecordList.length >= haveSuccessList.length) {
              this.finished = true
            }
          }
        } else {
          this.finished = true
          this.qryFlag = false
        }
        this.loading = false
        this.refreshing = false
        this.qryFlag = false
      } catch (e) {
        this.loading = false
        this.refreshing = false
        this.qryFlag = false
      }
    },
    // 月卡续费
    async queryMonthPayRecord () {
      try {
        const { data } = await axios({
          url: common.iotUrl + '/uuid/' + api.urlList.car_owner.bill.get_bill,
          method: 'POST',
          data: {
            // sid: 'yuen03',
            sid: this.sid,
            // openid: 'o1pxwuMcjjXt2YtCXSl3UC-ZbK-w',
            openid: this.openID,
            page_no: this.pageOption.page_no,
            page_size: this.pageOption.page_size,
            begin_time: this.currentDateStartFormat + ' 00:00:00',
            end_time: this.currentDateEndFormat + ' 23:59:59',
            version: 'v2',
            type: 2
          },
          header: {
            'content-type': 'application/json' // 默认值
          }
        })
        if (data.code === common.resCode.OK) {
          const haveSuccessList = data.list.filter(item => item.bill_status === 2)
          this.pageOption.total = haveSuccessList.length

          // const haveSuccessList = data.list

          if (this.refreshingMonth || this.qryFlag) {
            this.monthPayRecordList = haveSuccessList
            this.qryFlag = false
            this.loadingMonth = false
            this.finishedMonth = false
          } else {
            this.monthPayRecordList = [...this.monthPayRecordList, ...haveSuccessList]
            this.loadingMonth = false
            if (this.monthPayRecordList.length >= haveSuccessList.length) {
              this.finishedMonth = true
            }
          }
        } else {
          this.finishedMonth = true
          this.qryFlag = false
        }
        this.loadingMonth = false
        this.refreshingMonth = false
        this.qryFlag = false
      } catch (e) {
        this.loadingMonth = false
        this.refreshingMonth = false
        this.qryFlag = false
      }
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    // 停车
    onRefresh () {
      this.parkPayRecordList = []
      this.finished = false
      this.loading = true
      this.onLoad()
    },
    onLoad () {
      // 监听到即执行
      console.log('滑到底了')

      //   再次调用 查询 下一页数据
      if (this.refreshing) {
        // 下拉刷新
        this.pageOption.page_no = 1
        this.queryParkPayRecord()
      } else {
        if (this.errorFlag) {
          // 说明上次加载失败 现在重新加载
          this.pageOption.page_no = 1
          this.errorFlag = false
          this.queryParkPayRecord()
          return false
        }
        // 触底加载
        // if (this.parkPayRecordList.length >= this.pageOption.total) {
        //   this.finished = true
        // } else {
        this.pageOption.page_no = this.pageOption.page_no + 1
        this.queryParkPayRecord()
        // }
      }
    },
    // 月卡
    onRefreshMonth () {
      this.monthPayRecordList = []
      this.finishedMonth = false
      this.loadingMonth = true
      this.onLoadMonth()
    },
    onLoadMonth () {
      // 监听到即执行
      console.log('滑到底了')

      //   再次调用 查询 下一页数据
      if (this.refreshingMonth) {
        // 下拉刷新
        this.pageOption.page_no = 1
        this.queryMonthPayRecord()
      } else {
        if (this.errorFlagMonth) {
          // 说明上次加载失败 现在重新加载
          this.pageOption.page_no = 1
          this.errorFlagMonth = false
          this.queryMonthPayRecord()
          return false
        }
        // 触底加载
        if (this.monthPayRecordList.length >= this.pageOption.total) {
          this.finishedMonth = true
        } else {
          this.pageOption.page_no = this.pageOption.page_no + 1
          this.queryMonthPayRecord()
        }
      }
    }

  }
}
</script>
<style lang="scss" scoped>
.mainWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f7fc;
  height: 100vh;
  padding: 0.16rem 0;
  overflow: hidden;
  .rightIcon {
    width: 0.08rem;
    height: 0.14rem;
    margin: 0.03rem 0 0.03rem 0.05rem;
  }
  .lineline {
    height: 0.01rem;
    background-color: #e2e2e2;
    margin-top: 0.07rem;
    margin-bottom: 0.07rem;
  }
  .query_box {
    width: 3.43rem;
    // height: 5rem;
    display: flex;
    flex-direction: column;
    .query_box_item {
      background-color: rgba(255, 255, 255, 1);
      position: relative;
      /* width: 686rpx; */
      // height: 2.5rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      padding: 0.09rem 0.16rem 0.09rem 0.16rem;
      .query_box_item_tit {
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 0.16rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        // line-height: 1.375rem;
      }
      .query_box_item_right {
        /* width: 84rpx; */
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin: 0.01rem 0 0.01rem 0;
        .section {
          display: flex;
          /* width: 90rpx; */
          justify-content: space-between;
          align-items: center;
          .selPlateNum {
            font-size: 0.14rem;
          }
        }
      }
    }

    .query_box_item {
      background-color: rgba(255, 255, 255, 1);
      position: relative;
      // height: 2.5rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      padding: 0.09rem 0.16rem;
      .query_box_item_tit {
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 0.16rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        // line-height: 1.375rem;
      }
      .query_box_item_right {
        /* width: 84rpx; */
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin: 0.01rem 0;
        .section {
          display: flex;
          /* width: 90rpx; */
          justify-content: space-between;
          align-items: center;
          .showCommon {
            font-size: 0.14rem;
          }
          .rightIcon {
            width: 0.08rem;
            height: 0.14rem;
            margin: 0.03rem 0 0.03rem 0.05rem;
          }
        }
        .image_4-1 {
          position: absolute;
          left: 0;
          top: -0.01rem;
          width: 3.42rem;
          height: 0.01rem;
          background-color: #f0f0f0;
        }
      }
    }
  }

  .list_box {
    background-color: rgba(255, 255, 255, 1);
    width: 3.43rem;
    align-self: center;
    margin-top: 0.16rem;
    display: flex;
    flex-direction: column;
    padding: 0.08rem 0.01rem 0.01rem 0;
    overflow-y: auto;
    .itemBox {
      width: 3.25rem;
      flex-direction: column;
      display: flex;
      justify-content: space-between;
      margin: 0.04rem 0.07rem 0 0.1rem;
      .text-wrapper_6 {
        // width: 3.23rem;
        margin-top: 0.16rem;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        padding: 0 0.08rem;
        .plateNum {
          width: 1rem;
          display: flex;
          justify-content: space-between;
          .icon_3 {
            width: 0.2rem;
            height: 0.16rem;
            margin: 0.03rem 0 0 0;
          }
          .text_12 {
            overflow-wrap: break-word;
            color: rgba(153, 153, 153, 1);
            font-size: 0.14rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 0.2rem;
          }
        }
        .text_12 {
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.2rem;
        }
        .text_13 {
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.2rem;
        }
      }
      .mainBox {
        width: 3.25rem;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        /* margin: 8rpx 14rpx 0 20rpx; */
        .mainBoxLeft {
          /* width: 280rpx; */
          flex-direction: row;
          display: flex;
          justify-content: space-between;
          .mainBoxLeftIcon {
            width: 0.2rem;
            height: 0.16rem;
            margin: 0.03rem 0 0.43rem 0;
          }
          .mainBoxLeftCenter {
            display: flex;
            flex-direction: column;
            margin-left: 0.06rem;
            .mainBoxLeftCenterNum {
              overflow-wrap: break-word;
              color: rgba(51, 51, 51, 1);
              font-size: 0.16rem;
              font-family: PingFangSC-Regular;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 0.22rem;
              margin-right: 0.39rem;
            }
            .mainBoxLeftCenterType {
              overflow-wrap: break-word;
              color: rgba(153, 153, 153, 1);
              font-size: 0.12rem;
              font-family: PingFangSC-Regular;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 0.17rem;
              margin: 0.04rem 0.55rem 0 0;
            }

            .mainBoxLeftCenterDate {
              overflow-wrap: break-word;
              color: rgba(153, 153, 153, 1);
              font-size: 0.12rem;
              font-family: PingFangSC-Regular;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 0.17rem;
              margin-top: 0.02rem;
            }
          }
        }
        .mainBoxRight {
          margin-bottom: 0.19rem;
          display: flex;
          flex-direction: column;
          .mainBoxRightMoney {
            overflow-wrap: break-word;
            color: rgba(51, 51, 51, 1);
            font-size: 0.16rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 0.22rem;
            margin-left: 0.17rem;
          }
        }
      }
    }
    .info {
      .text-wrapper_12 {
        width: 3.22rem;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin: 0.15rem 0.18rem 0 0.1rem;
        .text_7 {
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.2rem;
        }

        .text_8 {
          overflow-wrap: break-word;
          color: rgba(51, 51, 51, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.2rem;
        }
      }
    }
  }
}
</style>
